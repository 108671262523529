import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useAudienceContext } from "../Components/AudienceContext/AudienceContext";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Snackbar,
  IconButton,
  Grid,
  selectClasses,
  Button,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import GlobalLoading from "./GlobalLoading";
import CreateAudienceFooter from "./CreateAudienceFooter";
import CustomDialog from "../Components/CustomDialog";
import FeaturedIcon from "../Assets/FeaturedIcon.svg";
import FeaturedIconError from "../Assets/FeaturedIconError.svg";
import APIS from "./APIS";
import {
  convertArrayToObjects,
  transformedCreateAudData,
  convertDistricts,
  capitalizeFirstLetter,
  convertToCamelCase,
  removeUnderscore,
  convertProvince,
  countFilters
} from "../helper/util";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useGlobalModalContext } from "../Components/GlobalModalContext/GlobalModalContext";
import ErrorMessageDialog from "../Components/AudienceList/ErrorMessageDialog";
import Sidebar from "../Components/sidebar";
import Navbar from "../Components/navbar";
import InsightsDialog from "../Components/AudienceList/InsightsDialog";
import { BarChartOutlined } from "@mui/icons-material";

const AudienceSummary = ({
  handleDemoDelete,
  handleCustomerInterestDelete,
  handleRegionDelete,
  handleProvinceDelete,
  handleDistrictDelete,
  handleCommRelevantDelete,
}) => {
  const { audienceSummaryData, audienceEnhancement, clearEnhancement, setAudienceSummaryData } =
    useAudienceContext();
  const {
    audienceSegmentName = "",
    audienceId = "",
    demographicsselectedFilters = {},
    custInterestselectedFilters = {},
    selectedRegions = [],
    selectedProvinces = [],
    selectedDistricts = [],
    commRelevantselectedFilters = {},
    conjunctionType = "AND",
    conjunctionTypeWeb = "AND",
    locationTrafficData = [],
    websiteTrafficData = [],
    selectedPeriod = 1,
    selectedPeriodLoc = 1,
    totalCount = 0,
    isAudienceCustom = false
  } = audienceSummaryData;
  const { mode } = useParams();
  const navigate = useNavigate();
  const [audName, setAudName] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [audNameError, setAudNameError] = useState(false);
  const [audId, setAudId] = useState("");
  const [loadingCreateAud, setLoadingCreateAud] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [showCreateAudForm, setShowCreateAudForm] = useState(false);
  const [audTotalCount, setAudTotalCount] = useState(0);
  const [pushMode, setPushMode] = useState(false);
  const [audTotalCategories, setAudTotalCategories] = useState(0);
  const showCreateAudOptions = () => {
    navigate("/CreateAudience");
  };
  const { showModal } = useGlobalModalContext();
  const styleLeft = {
    display: "flex",
    minWidth: "20%",
    fontSize: "12px",
    paddingRight: "8px",
  };
  const isInitialMount = useRef(true);

  useEffect(() => {
    
    if (mode === "AudiencePush") {
      setPushMode(true);
      setAudName(audienceSegmentName);
      setAudId(audienceId);
    }

    handleCalculateTotalCategories();
    const element = document.getElementById("nomal-footer");
    if (element) {
      element.style.display = "none";
    }
    return () => {
      if (element) {
        element.style.display = "";
      }
    };
  }, []);

  const handleCalculateTotalCategories = () => {
    const demoCount = Object.keys(demographicsselectedFilters)
      .map((filterType) => demographicsselectedFilters[filterType]?.length || 0)
      .reduce((acc, count) => acc + count, 0);

    const commRelCount = Object.keys(commRelevantselectedFilters)
      .map((filterType) => commRelevantselectedFilters[filterType]?.length || 0)
      .reduce((acc, count) => acc + count, 0);

    const geogCount =
      selectedRegions.length +
      selectedProvinces.length +
      selectedDistricts.filter((item) => item.district).length;

    const custInterestCount = countFilters(custInterestselectedFilters);

    setAudTotalCategories(
      demoCount +
        commRelCount +
        geogCount +
        custInterestCount +
        locationTrafficData.length +
        websiteTrafficData.length
    );
  };

  const navigateToAudienceList = () => {
    clearEnhancement();
    navigate("/Audience");
  };

  const navigateAudiencePush = () => {
    clearEnhancement();
    navigate("/AudiencePerformPush/" + audId, {
      state: { audienceSize: totalCount ? totalCount : audTotalCount },
    });
  };

  const handleAudienceName = (event) => {
    setAudNameError(false);
    setAudName(event.target.value);
  };

  const handleCreateAudience = async () => {
    await handleCalculateTotalCategories();
    if(audTotalCategories == 0) return;
    if (audName === "") {
      setAudNameError(true);
    } else {
      setOpenConfirmDialog(true);
    }
  };

  const handleCloseDialogCreate = () => {
    setOpenConfirmDialog(false);
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    setAudNameError(false);
  };

  const handleConfirmCreation = () => {
    createAudience();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigateToAudienceList();
  };

  const createAudience = async () => {
    const createAudData = {
      audienceSegementName: audName,
      filters: transformFilters(),
    };
    try {
      setOpenConfirmDialog(false);
      setLoadingCreateAud(true);
      let response = await APIS.createAudience(
        createAudData,
        localStorage.getItem("token")
      );
      if (response.status == "success") {
        setOpenDialog(true);
        setLoadingCreateAud(false);
        setAudId(response.audienceId);
      } else if (response.status == "fail") {
        setLoadingCreateAud(false);
        showModal(
          <ErrorMessageDialog
            title={response?.message}
            description={response?.error}
          />
        );
      } else {
        setLoadingCreateAud(false);
        showModal(<ErrorMessageDialog title={response?.message} />);
      }
    } catch (error) {
      setLoadingCreateAud(false);
    }
  };

  const transformFilters = () => {
    return {
      demo: transformedCreateAudData(demographicsselectedFilters), // correct
      geog: {
        resident_location_region_en_cat: {
          filters: convertArrayToObjects(selectedRegions),
        },
        resident_location_province_en_cat: {
          filters: convertArrayToObjects(selectedProvinces), // corrected
        },
        resident_location_district_en_cat: {
          filters: convertDistricts(selectedDistricts), // corrected
        },
      },
      segment: {
        operation: conjunctionType,
        interests: custInterestselectedFilters,
      }, // corrected
      comm_relevance: transformedCreateAudData(commRelevantselectedFilters), // correct
      locationTraffic: {
        //period: Number(selectedPeriodLoc),
        filters: locationTrafficData, // corrected
      },
      websiteTraffic: {
        operation: conjunctionTypeWeb,
        //period: Number(selectedPeriod),
        filters: websiteTrafficData, // corrected
      },
      audienceEnhancement: audienceEnhancement,
    };
  };

  const handleViewInsights = async () => {
    const chartsData = await getChartsData();
    const mapData = await getMapData();
    showModal(
      <InsightsDialog
        insightsChartInfo={chartsData}
        insightsMapInfo={mapData}
      />
    );
  };

  const getChartsData = async () => {
    const formData = transformFilters();
    setLoadingCreateAud(true);
    try {
      const response = await APIS.getAudienceInsightsForSummary(
        localStorage.getItem("token"),
        formData
      );
      if (response.status === "success") {
        setLoadingCreateAud(false);
        return response.data;
        //setInsightsChartInfo(response.data);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        setLoadingCreateAud(false);
      }
    } catch (error) {
      setErrorSnackbar("Some Error Occurred");
      setLoadingCreateAud(false);
    }
  };

  const getMapData = async () => {
    const formData = transformFilters();
    setLoadingCreateAud(true);
    try {
      const response = await APIS.getAudienceLocInsightsForSummary(
        localStorage.getItem("token"),
        formData
      );
      if (response.status === "success") {
        setLoadingCreateAud(false);
        return response.data;
        //setInsightsChartInfo(response.data);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        setLoadingCreateAud(false);
      }
    } catch (error) {
      setErrorSnackbar("Some Error Occurred");
      setLoadingCreateAud(false);
    }
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {loadingCreateAud && <GlobalLoading />}
      <div className="inner-wrapper">
        <Grid container>
          <Grid item xs={12}>
            <Navbar />
          </Grid>
          <Grid item xs={1}>
            <Sidebar />
          </Grid>
          <Grid item xs={11} padding={4} mb={20}>
            <h2>Audience Summary</h2>
            <div className="sub-title">To set audience segment</div>

            <div className="back-text" onClick={navigateToAudienceList}>
                {/* <ArrowBackIcon></ArrowBackIcon>
                <span>{"Back to Audience List".toLocaleUpperCase()}</span>{" "} */}
                <Button variant='contained' onClick={navigateToAudienceList}><b>Back to Audience List</b></Button>
              </div>

            <br></br>

            <div>
              <TextField
                label="Audience Segment Name"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                type="text"
                required
                placeholder="Audience Segment Name"
                sx={{ width: "50%" }}
                value={audName}
                onChange={handleAudienceName}
                error={audNameError}
                helperText={
                  audNameError ? "Please enter Audience Segment Name." : ""
                }
                disabled={pushMode}
              />
            </div>
            <br></br>
            <Button
              variant="contained"
              onClick={handleViewInsights}
              sx={{
                borderRadius: "4px !important",
                fontWeight: "700 !important",
                marginBottom: "16px",
              }}
            >
              <BarChartOutlined />
              View Insights
            </Button>
            <br />
            <div
              style={{
                paddingBottom: "16px",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Estimated Audience
            </div>
            {Object.keys(demographicsselectedFilters)?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="aud-summary-acc-summ"
                >
                  <span style={{ fontSize: "16px" }}>
                    <b>Demographics</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(demographicsselectedFilters)?.map(
                    ([key, value], index) => (
                      <div
                        style={{ display: "flex", marginBottom: "10px" }}
                        key={key + index}
                      >
                        <div style={styleLeft}>
                          {capitalizeFirstLetter(convertToCamelCase(key))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {value.map((item, idx) => (
                            <Stack
                              key={item.value + idx}
                              direction="row"
                              useFlexGap
                              flexWrap="wrap"
                              spacing={1}
                            >
                              <Chip
                                style={{ fontSize: "12px" }}
                                label={capitalizeFirstLetter(item.value)}
                                className="aud-summary-chip"
                                size="small"
                                variant="outlined"
                              />
                            </Stack>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            )}

            {Object.keys(custInterestselectedFilters)?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="cust-interest-content"
                  id="cust-interest-header"
                  className="aud-summary-acc-summ"
                >
                  <span style={{ fontSize: "16px" }}>
                    <b>Customer Interest</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {Object.entries(custInterestselectedFilters)?.map(
                      ([category, subcategories], index) => (
                        <div key={category + index}>
                          {Object.entries(subcategories).map(
                            ([subcategory, filters], idx) => (
                              <div
                                key={category + subcategory + idx}
                                style={{
                                  display: "flex",
                                  marginBottom: "10px",
                                }}
                              >
                                <div style={styleLeft}>
                                  {capitalizeFirstLetter(category)} -{" "}
                                  {removeUnderscore(subcategory)}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  {filters.filters.map((filter, idx2) => (
                                    <Stack
                                      key={
                                        category +
                                        subcategory +
                                        filter.value +
                                        idx2
                                      }
                                      direction="row"
                                      useFlexGap
                                      flexWrap="wrap"
                                      spacing={1}
                                    >
                                      <Chip
                                        style={{ fontSize: "12px" }}
                                        className="aud-summary-chip"
                                        size="small"
                                        variant="outlined"
                                        //deleteIcon={<CloseOutlinedIcon />}
                                        label={capitalizeFirstLetter(
                                          filter.value
                                        )}
                                      />
                                    </Stack>
                                  ))}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}

            {(selectedRegions.length > 0 ||
              selectedProvinces.length > 0 ||
              selectedDistricts.length > 0) && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="geographic-content"
                  id="geographic-header"
                  className="aud-summary-acc-summ"
                >
                  <span style={{ fontSize: "16px" }}>
                    <b>Geographics</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={styleLeft}>Region</div>
                    <div style={{ display: "flex" }}>
                      <Stack
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        spacing={1}
                      >
                        {selectedRegions.map((value, index) => (
                          <Chip
                            style={{ fontSize: "12px" }}
                            key={value + index}
                            label={capitalizeFirstLetter(value)}
                            className="aud-summary-chip"
                            size="small"
                            variant="outlined"
                          />
                        ))}
                      </Stack>
                    </div>
                  </div>

                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={styleLeft}>Province</div>
                    <div style={{ display: "flex", width: "80%" }}>
                      <Stack
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        spacing={1}
                      >
                        {selectedProvinces.map(
                          (value, index) =>
                            value && (
                              <Chip
                                style={{ fontSize: "12px" }}
                                key={value + index}
                                label={convertProvince(value)}
                                className="aud-summary-chip"
                                size="small"
                                variant="outlined"
                              />
                            )
                        )}
                      </Stack>
                    </div>
                  </div>

                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={styleLeft}>District</div>
                    <div style={{ display: "flex" }}>
                      <Stack
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        spacing={1}
                      >
                        {selectedDistricts.map(
                          (value, index) =>
                            value.district && (
                              <Chip
                                style={{ fontSize: "12px", height: "auto" }}
                                key={value + index}
                                label={
                                  <>
                                    <div>
                                      {capitalizeFirstLetter(value.district)}
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                      Province:{" "}
                                      {convertProvince(value.province)}
                                    </div>
                                  </>
                                }
                                className="aud-summary-chip"
                                size="small"
                                variant="outlined"
                              />
                            )
                        )}
                      </Stack>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}

            {Object.keys(commRelevantselectedFilters)?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="comm-rel-content"
                  id="comm-rel-header"
                  className="aud-summary-acc-summ"
                >
                  <span style={{ fontSize: "16px" }}>
                    <b>Commercial Relevant</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(commRelevantselectedFilters)?.map(
                    ([key, value], index) => (
                      <div
                        style={{ display: "flex", marginBottom: "10px" }}
                        key={key + index}
                      >
                        <div style={styleLeft}>
                          {capitalizeFirstLetter(convertToCamelCase(key))}
                        </div>
                        {key === "TDG_Customer_Group" ? (
                          <div style={{ display: "flex" }}>
                            {value.map((item, idx) => (
                              <Stack
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                                spacing={1}
                                key={key + item.value + idx}
                              >
                                <Chip
                                  style={{ fontSize: "12px" }}
                                  label={
                                    item.value == 1
                                      ? "TDG CUSTOMER"
                                      : "NON-TDG CUSTOMER"
                                  }
                                  key={item.value + idx}
                                  className="aud-summary-chip"
                                  size="small"
                                  variant="outlined"
                                  //deleteIcon={<CloseOutlinedIcon />}
                                />
                              </Stack>
                            ))}
                          </div>
                        ) : [
                            "Android_Box_MAU",
                            "Foreigner",
                            "True_ID_MAU",
                          ].includes(key) ? (
                          <div style={{ display: "flex" }}>
                            {value.map((item, idx) => (
                              <Stack
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                                spacing={1}
                                key={key + item.value + idx}
                              >
                                <Chip
                                  style={{ fontSize: "12px" }}
                                  label={item.value == 1 ? "TRUE" : "FALSE"}
                                  key={item.value + idx}
                                  className="aud-summary-chip"
                                  size="small"
                                  variant="outlined"
                                  //deleteIcon={<CloseOutlinedIcon />}
                                />
                              </Stack>
                            ))}
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            {value.map((item, idx) => (
                              <Stack
                                direction="row"
                                useFlexGap
                                flexWrap="wrap"
                                spacing={1}
                                key={key + item.value + idx}
                              >
                                <Chip
                                  style={{ fontSize: "12px" }}
                                  label={item.value}
                                  key={item.value + idx}
                                  className="aud-summary-chip"
                                  size="small"
                                  variant="outlined"
                                  //deleteIcon={<CloseOutlinedIcon />}
                                />
                              </Stack>
                            ))}
                          </div>
                        )}
                      </div>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            )}

            {locationTrafficData?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="location-traffic-content"
                  id="location-traffic-header"
                  className="aud-summary-acc-summ"
                >
                  <span>
                    <b>Location Traffic</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {locationTrafficData.map((rowData, index) => (
                      <ListItem
                        key={index}
                        className="loc-traffic-list"
                        sx={{
                          border: "1px solid #EAECF0",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          padding: "0px 8px",
                        }}
                      >
                        <div style={{ width: "40%" }}>
                          <ListItemText
                            primary={
                              <Typography
                                component="span"
                                sx={{ fontSize: "14px" }}
                              >
                                Latitude: <b>{rowData.home_lat}</b>
                              </Typography>
                            }
                            className="loc-traffic-table-cell"
                          />
                        </div>
                        <div style={{ textWrap: "wrap", width: "40%" }}>
                          <ListItemText
                            primary={
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "14px",
                                  margin: "none",
                                  width: "30%",
                                }}
                              >
                                Longitude: <b>{rowData.home_long}</b>
                              </Typography>
                            }
                            className="loc-traffic-table-cell"
                          />
                        </div>
                        <div style={{ textWrap: "wrap", width: "20%" }}>
                          <ListItemText
                            primary={
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: "14px",
                                  margin: "none",
                                  width: "30%",
                                }}
                              >
                                Radius: <b>{rowData.radius} m</b>
                              </Typography>
                            }
                            className="loc-traffic-table-cell"
                          />
                        </div>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}

            {websiteTrafficData?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="location-traffic-content"
                  id="location-traffic-header"
                  className="aud-summary-acc-summ"
                >
                  <span>
                    <b>Domain Traffic</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <div className="summary-period">
                    Period: <b>{selectedPeriod}</b>
                  </div> */}
                  <List>
                    {websiteTrafficData.map((rowData, index) => (
                      <ListItem
                        key={index}
                        className="loc-traffic-list"
                        sx={{
                          border: "1px solid #EAECF0",
                          borderRadius: "8px",
                          marginBottom: "8px",
                          padding: "0px 8px",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              sx={{ fontSize: "14px" }}
                            >
                              Domain URL: <b>{rowData.webUrl}</b>
                            </Typography>
                          }
                          className="loc-traffic-table-cell"
                          sx={{
                            fontSize: "14px",
                            margin: "none",
                            width: "30%",
                          }}
                        />
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "14px",
                                margin: "none",
                                width: "30%",
                              }}
                            >
                              Match Criteria: <b>{rowData.matchCriteria}</b>
                            </Typography>
                          }
                          className="loc-traffic-table-cell"
                        />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}

            {audienceEnhancement?.include?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="aud-summary-acc-summ"
                >
                  <span style={{ fontSize: "16px" }}>
                    <b>Define Segment Criteria</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(audienceEnhancement?.include)?.map(
                    ([key, value], index) => (
                      <div
                        style={{ display: "flex", marginBottom: "10px" }}
                        key={key + index}
                      >
                        <div style={styleLeft}>
                          Segment Criteria {value.number}
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Stack
                            key={"askjfnasfnj"}
                            direction="row"
                            useFlexGap
                            flexWrap="wrap"
                            spacing={1}
                          >
                            <Chip
                              style={{ fontSize: "12px" }}
                              label={value.operation}
                              className="aud-summary-chip"
                              size="small"
                              variant="outlined"
                            />
                            <Chip
                              style={{ fontSize: "12px" }}
                              label={value.audienceName}
                              className="aud-summary-chip"
                              size="small"
                              variant="outlined"
                            />
                          </Stack>
                        </div>
                      </div>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            )}

            {audienceEnhancement?.exclude?.length > 0 && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="aud-summary-acc-summ"
                >
                  <span style={{ fontSize: "16px" }}>
                    <b>Exclude Audience Segments</b>
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={styleLeft}>Audience Exclusion</div>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {audienceEnhancement?.exclude.map((item, idx) => (
                        <Stack
                          key={item + idx}
                          direction="row"
                          useFlexGap
                          flexWrap="wrap"
                          spacing={1}
                        >
                          <Chip
                            style={{ fontSize: "12px" }}
                            label={item.audienceName}
                            className="aud-summary-chip"
                            size="small"
                            variant="outlined"
                          />
                        </Stack>
                      ))}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}

            <div className="aud-summary-aud-size">
              Current Audience Size : {totalCount ? totalCount : audTotalCount}
            </div>
            <br></br>

            {!pushMode && (
              <CreateAudienceFooter
                loadingCreateAud={loadingCreateAud}
                createAudience={handleCreateAudience}
                onBack={showCreateAudOptions}
                buttonLabel="Create Audience"
              />
            )}

            {pushMode && (
              <CreateAudienceFooter
                loadingCreateAud={loadingCreateAud}
                createAudience={navigateAudiencePush}
                onBack={navigateToAudienceList}
                buttonLabel="Push Audience"
              />
            )}
          </Grid>
        </Grid>

        <CustomDialog
          dialogTitle={"Audience Name is Required"}
          dialogContent={
            "Audience Name is a required field. Please enter Audience Name."
          }
          openDialog={audNameError}
          image={FeaturedIconError}
          noActions={true}
          leftButtonLabel={"Close"}
          leftButtonAction={handleCancel}
        />

        <CustomDialog
          dialogTitle={"Confirm Audience Creation"}
          dialogContent={
            "Your new audience segment will be saved. Once created, you can view the audience summary, push the audience to your campaign, or return to the audience list. Do you want to proceed?"
          }
          leftButtonLabel={"Cancel"}
          rightButtonLabel={"Confirm Creation"}
          openDialog={openConfirmDialog}
          handleCloseDialog={handleCloseDialogCreate}
          image={FeaturedIcon}
          leftButtonAction={handleCancel}
          rightButtonAction={handleConfirmCreation}
        />

        <CustomDialog
          dialogTitle={"Audience Created Successfully!"}
          dialogContent={
            "Your new audience segment has been created and saved. You can now view the audience summary, push the audience to your campaign, or return to the audience list"
          }
          leftButtonLabel={"Back to audience list"}
          rightButtonLabel={"Push Audience"}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          image={FeaturedIcon}
          leftButtonAction={navigateToAudienceList}
          rightButtonAction={navigateAudiencePush}
        />

        <Snackbar
          open={errorSnackbar ? true : false}
          autoHideDuration={6000}
          onClose={handleCloseSanckBar}
          message={errorSnackbar}
          action={action}
          sx={{ zIndex: "10000" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </div>
    </>
  );
};
export default AudienceSummary;
