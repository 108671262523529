import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { Visibility, MoreVert, Close } from "@mui/icons-material";
import { formatDate } from "../../helper/util";
import { useGlobalModalContext } from "../GlobalModalContext/GlobalModalContext";
import AudienceStatusDialog from "./AudienceStatusDialog";
import CustomTable from "../ui/Table/CustomTable";
import { useNavigate } from "react-router-dom";
import DeleteAudienceDialog from "../DeleteAudienceDialog";
import GlobalLoading from "../../Pages/GlobalLoading";
import APIS from "../../Pages/APIS";
import { useAudienceContext } from "../AudienceContext/AudienceContext";
import { transformedData, convertToArray } from "../../helper/util";
import ErrorMessageDialog from "./ErrorMessageDialog";
import {
  convertArrayToObjects,
  transformedCreateAudData,
} from "../../helper/util";

const AudienceTable = ({
  count,
  data,
  rowPerPage,
  page,
  handlePageChange,
  handleChangeRowsPerPage,
  handleDeleteAudience,
  loading,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setDataRow] = useState("");
  const { showModal } = useGlobalModalContext();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState("");
  const [menuDisabled, setMenuDisabled] = useState(false); // Add state for disabling menu items
  const {
    setAudienceSummaryData,
    audienceSummaryData,
    setAudienceEnhancement,
  } = useAudienceContext();
  
  const columns = [
    {
      name: "No.",
      id: "number",
    },
    {
      name: "Audience Name",
      id: "audienceName",
      minWidth: "100",
    },
    {
      name: "Created Date",
      id: "createdDate",
      element: (row) => `${formatDate(row.createdDate?.value)}`,
    },
    {
      name: "Updated Date",
      id: "updatedDate",
      element: (row) => `${formatDate(row.updatedDate?.value)}`,
    },

    {
      name: "Created By",
      id: "createdBy",
    },
    {
      name: "Audience Size",
      id: "audienceSize",
    },
    {
      name: "Source",
      id: "source",
    },
    {
      name: "History",
      id: "createdBy",
      element: (row) => (
        <IconButton
          onClick={() =>
            showModal(<AudienceStatusDialog selectedAudience={row} />)
          }
        >
          <Visibility />
        </IconButton>
      ),
    },
    {
      id: "id",
      minWidth: 50,
      width: 50,
      element: (row) => {
        const buttonId = "basic-button";
        const menuId = "basic-menu";
        const open = Boolean(anchorEl);
        return (
          <>
            <Button
              id={buttonId}
              aria-controls={open ? menuId : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setDataRow(row);
              }}
              disabled={menuDisabled} // Disable button when menu items are disabled
            >
              <MoreVert />
            </Button>

            <Menu
              id={menuId}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": buttonId,
              }}
            >
              <MenuItem
                onClick={() => {
                  setMenuDisabled(true); // Disable menu items
                  navigateAudienceSummary(rowData.id, rowData.audienceName);
                }}
                disabled={menuDisabled} // Disable this item if others are clicked
              >
                Perform Audience Push
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setMenuDisabled(true); // Disable menu items
                  navigateLocationInsights(rowData.id);
                }}
                disabled={menuDisabled} // Disable this item if others are clicked
              >
                View Insights
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setMenuDisabled(true); // Disable menu items
                  showModal(
                    <DeleteAudienceDialog
                      onSuccess={() => {
                        handleDeleteAudience(rowData.id);
                        setAnchorEl(null);
                        setMenuDisabled(false); // Re-enable menu items after action is complete
                      }}
                    />
                  );
                }}
                disabled={menuDisabled} // Disable this item if others are clicked
              >
                Delete Audience
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const navigateLocationInsights = (audienceId) => {
    setGlobalLoading(true);
    navigate("/LocationInsights/" + audienceId, {
      state: { audienceId: audienceId },
    });
    setMenuDisabled(false); // Re-enable menu items after navigation
    setGlobalLoading(false);
  };

  const navigateAudienceSummary = (audienceId, audName) => {
    fetchAudienceSummaryData(audienceId, audName);
  };

  const fetchAudienceSummaryData = async (audienceId, audName) => {
    setGlobalLoading(true);
    try {
      const response = await APIS.getAudienceSummary(
        localStorage.getItem("token"),
        audienceId
      );
      if (response.status === "success") {
        setAudienceSummaryData({
          audienceSegmentName: audName,
          audienceId: audienceId,
          demographicsselectedFilters:
            transformedData(response.summary?.demo) || {},
          selectedRegions:
            convertToArray(
              response.summary?.geog?.resident_location_region_en_cat
            ) || [],
          selectedProvinces:
            convertToArray(
              response.summary?.geog?.resident_location_province_en_cat
            ) || [],
          selectedDistricts:
            convertToArray(
              response.summary?.geog?.resident_location_district_en_cat
            ) || [],
          custInterestselectedFilters: response.summary?.segment?.interests,
          commRelevantselectedFilters:
            transformedData(response.summary?.comm_relevance) || {},
          locationTrafficData: response.summary?.locationTraffic?.filters || [],
          websiteTrafficData: response.summary?.websiteTraffic?.filters || [],
          selectedPeriodLoc: response.summary?.locationTraffic?.period || 0,
          selectedPeriod: response.summary?.websiteTraffic?.period || 0,
          conjunctionTypeWeb: response.summary?.websiteTraffic?.operation,
          conjunctionType: response.summary?.segment?.operation,
          isAudienceCustom: response.summary?.isAudienceCustom,
        });
        setAudienceEnhancement(response.summary?.audienceEnhancement);
        await navigateToAudienceSummary(response.summary);
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
      }
    } catch (error) {
      setErrorSnackbar("Some Error Occurred");
    } finally {
      setGlobalLoading(false);
      setMenuDisabled(false); // Re-enable menu items after action is complete
    }
  };

  const navigateToAudienceSummary = async (payload) => {
    const status = await handleTotalCount(payload);
    if (status === "success") {
      navigate("/AudienceSummary/AudiencePush");
    }
  };

  const handleTotalCount = async (payload) => {
    const totalCountData = payload;
    try {
      setGlobalLoading(true);
      const response = await APIS.calculateTotalCount(
        totalCountData,
        localStorage.getItem("token")
      );
      if (response.status === "success") {
        setAudienceSummaryData((prevData) => ({
          ...prevData,
          totalCount: response.count,
        }));
        return "success";
      } else if (response.status === "fail") {
        showModal(
          <ErrorMessageDialog
            title={response?.message}
            description={response?.error}
          />
        );
        return "fail";
      } else {
        setErrorSnackbar(response?.message || "Some Error Occurred");
        return "error";
      }
    } catch (error) {
      setErrorSnackbar("Some Error Occurred");
      return "error";
    } finally {
      setGlobalLoading(false);
      setMenuDisabled(false); // Re-enable menu items after action is complete
    }
  };

  const handleCloseSanckBar = () => {
    setErrorSnackbar("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSanckBar}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {globalLoading && <GlobalLoading />}
      <CustomTable
        columns={columns}
        data={data}
        count={count}
        isLoading={loading}
        rowPerPage={rowPerPage}
        page={page}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Snackbar
        open={!!errorSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSanckBar}
        message={errorSnackbar}
        action={action}
      />
    </>
  );
};

export default AudienceTable;
